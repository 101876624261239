import React, { useState } from 'react';
import './ha.css';
import { useNavigate } from 'react-router-dom';
import { FaChartBar } from 'react-icons/fa';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import * as XLSX from 'xlsx';
import ModalAlert from '../template/ModalAlert';

const Ha: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewData, setPreviewData] = useState<any[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const [modalAlertOpen, setModalAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertType, setAlertType] = useState<'success' | 'error'>('success');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];
            setSelectedFile(file);

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target?.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                const headers = worksheet[0];
                const rows = worksheet.slice(1);

                const validationErrors: string[] = [];
                const validatedData = (rows as any[][]).map((row, index) => {
                    const [
                        MesAnio, Sociedad, DNI, Técnico, Email, CCoste, PCenter, CodProy, Contrato, NombrePOCorto, NombrePO, CentroDeCoste, Categoría, Norm, Ext, Noct, Guar, SocExt
                    ] = row;

                    // Validaciones
                    if (!MesAnio || isNaN(Date.parse(MesAnio))) validationErrors.push(`Error en fila ${index + 2}: "Mes-Año" no es una fecha válida.`);
                    if (Sociedad !== 'INETUM COLOMBIA') validationErrors.push(`Error en fila ${index + 2}: "Sociedad" debe ser "INETUM COLOMBIA".`);
                    if (!/^[a-zA-Z0-9]+$/.test(DNI)) validationErrors.push(`Error en fila ${index + 2}: "DNI" debe ser alfanumérico.`);
                    if (typeof Técnico !== 'string') validationErrors.push(`Error en fila ${index + 2}: "Técnico" debe ser texto.`);
                    if (typeof Email !== 'string') validationErrors.push(`Error en fila ${index + 2}: "E-mail" debe ser texto.`);
                    if (!/^[a-zA-Z0-9]+$/.test(CCoste)) validationErrors.push(`Error en fila ${index + 2}: "C.Coste." debe ser alfanumérico.`);
                    if (!/^[a-zA-Z0-9]+$/.test(PCenter)) validationErrors.push(`Error en fila ${index + 2}: "P.Center." debe ser alfanumérico.`);
                    if (!/^[a-zA-Z0-9]+$/.test(CodProy)) validationErrors.push(`Error en fila ${index + 2}: "Cod. Proy." debe ser alfanumérico.`);
                    if (!/^[a-zA-Z0-9]+$/.test(Contrato)) validationErrors.push(`Error en fila ${index + 2}: "Contrato" debe ser alfanumérico.`);
                    if (!/^[a-zA-Z0-9]+$/.test(NombrePOCorto)) validationErrors.push(`Error en fila ${index + 2}: "Nombre PO Corto" debe ser alfanumérico.`);
                    if (!/^[a-zA-Z0-9]+$/.test(NombrePO)) validationErrors.push(`Error en fila ${index + 2}: "Nombre PO" debe ser alfanumérico.`);
                    if (!/^[a-zA-Z0-9]+$/.test(CentroDeCoste)) validationErrors.push(`Error en fila ${index + 2}: "Centro de coste" debe ser alfanumérico.`);
                    if (typeof Categoría !== 'string') validationErrors.push(`Error en fila ${index + 2}: "Categoría" debe ser texto.`);
                    if (isNaN(Number(Norm))) validationErrors.push(`Error en fila ${index + 2}: "Norm" debe ser numérico.`);
                    if (isNaN(Number(Ext))) validationErrors.push(`Error en fila ${index + 2}: "Ext" debe ser numérico.`);
                    if (isNaN(Number(Noct))) validationErrors.push(`Error en fila ${index + 2}: "Noct" debe ser numérico.`);
                    if (isNaN(Number(Guar))) validationErrors.push(`Error en fila ${index + 2}: "Guar" debe ser numérico.`);
                    if (typeof SocExt !== 'string') validationErrors.push(`Error en fila ${index + 2}: "Soc Ext" debe ser texto.`);

                    return {
                        MesAnio, Sociedad, DNI, Técnico, Email, CCoste, PCenter, CodProy, Contrato, NombrePOCorto, NombrePO, CentroDeCoste, Categoría, Norm, Ext, Noct, Guar, SocExt
                    };
                });
                setPreviewData(validatedData);
                setErrors(validationErrors);
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const handleFileUpload = () => {
        if (selectedFile) {
            if (errors.length > 0) {
                setAlertMessage("Errores en el archivo:\n" + errors.join('\n'));
                setAlertType('error');
            } else {
                setAlertMessage("Archivo subido correctamente: " + selectedFile.name);
                setAlertType('success');
            }
        } else {
            setAlertMessage("Por favor selecciona un archivo antes de subirlo.");
            setAlertType('error');
        }
        setModalAlertOpen(true);
    };

    const handleRedirectTotrackingHa = () => {
        navigate("/trackingHa");
    };

    return (
        <div className="ha-container">
            <h1 className="ha-title">Módulo H.A</h1>

            <div className="ha-card">
                <h2 className="ha-subtitle">Importar Reporte HA</h2>
                <div className="ha-file-input-container">
                    <input
                        type="file"
                        accept=".xlsx"
                        onChange={handleFileChange}
                        id="file-input"
                        className="ha-file-input"
                    />
                    <label htmlFor="file-input" className="ha-file-label">
                        Seleccionar Archivo
                    </label>
                    <p className="ha-file-info">
                        {selectedFile ? `Archivo seleccionado: ${selectedFile.name}` : 'No has seleccionado ningún archivo'}
                    </p>
                </div>
                <button onClick={handleFileUpload} className="ha-btn">Subir Archivo</button>
            </div>

            {errors.length > 0 && (
                <div className="ha-card ha-errors">
                    <h2 className="ha-subtitle">Errores Encontrados</h2>
                    <ul>
                        {errors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            {previewData.length > 0 ? (
                <div className="ha-card">
                    <h2 className="ha-subtitle">Previsualización de los Datos</h2>
                    <SimpleBar style={{maxHeight: '600px'}}>
                        <table className="ha-table">
                            <thead>
                            <tr>
                                <th>Mes-Año</th>
                                <th>Sociedad</th>
                                <th>DNI</th>
                                <th>Técnico</th>
                                <th>E-mail</th>
                                <th>C.Coste.</th>
                                <th>P.Center.</th>
                                <th>Cod. Proy.</th>
                                <th>Contrato</th>
                                <th>Nombre PO Corto</th>
                                <th>Nombre PO</th>
                                <th>Centro de coste</th>
                                <th>Categoría</th>
                                <th>Norm</th>
                                <th>Ext</th>
                                <th>Noct</th>
                                <th>Guar</th>
                                <th>Soc Ext</th>
                            </tr>
                            </thead>
                            <tbody>
                            {previewData.map((data, index) => (
                                <tr key={index}>
                                    {Object.values(data).map((value, i) => (
                                        <td key={i}>{String(value)}</td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </SimpleBar>
                </div>
            ) : (
                <div className="ha-card">
                    <h2 className="ha-subtitle">Previsualización de los datos</h2>
                    <p>No hay datos para mostrar</p>
                </div>
            )}
            <button onClick={handleRedirectTotrackingHa} className="ha-btn ha-btn-map">
                <FaChartBar style={{marginRight: '8px'}}/> Ir seguimiento HA
            </button>
            <ModalAlert
                isOpen={modalAlertOpen}
                onClose={() => setModalAlertOpen(false)}
                message={alertMessage}
                type={alertType}
            />
        </div>

    );
};

export default Ha;
