import React, { useEffect, useState, useContext } from 'react';
import { Table, Container, Button, Pagination, Form } from 'react-bootstrap';
import { getUsers } from '../../api/user';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import NotAccess from '../NotAccess';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import './UserList.css';
import { FaSearch, FaEdit } from 'react-icons/fa';
import { FaSquarePlus } from "react-icons/fa6";
import { TbEditCircle } from "react-icons/tb";
import { IoCloudDownloadSharp } from "react-icons/io5";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Console } from 'console';
import * as XLSX from 'xlsx'; 

const UserList = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [displayedUsers, setDisplayedUsers] = useState<any[]>([]);
    const [tableHeaders, setTableHeaders] = useState<any[]>([]);
    const [modelTabs, setModelTabs] = useState<number>(0);
    const [pagination, setPagination] = useState({
        page: 1,
        size: 20,
        numberPages: 1
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { token } = useContext(AuthContext)!;
    const navigate = useNavigate();
    const [animateTable, setAnimateTable] = useState<boolean>(false);

    useEffect(() => {
        setSearchTerm('');
        if (token) {
            fetchUsers();
        }
    }, [token, modelTabs]);

    useEffect(() => {
        setSearchTerm('');
        configTableHeaders();
    }, [modelTabs]);

    useEffect(() => {
        applyFiltersAndPaginate();
    }, [searchTerm, users, pagination.page, pagination.size]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await getUsers({
                token: token!,
                modelTabs: modelTabs,
            });

            if (!Array.isArray(data)) {
                console.error('Invalid API Response:', data);
                setUsers([]);
                setDisplayedUsers([]);
                return;
            }

            const content = data.map((user: any) => ({
                ...user,
                ...(modelTabs === 0 ? { ...user } : { ...user.person }),
                roleId: user.role?.id,
                roleName: user.role?.name,
                firstNameUser: user.person?.firstName,
                lastNameUser: user.person?.lastName,
                emailUser: user.person?.email,
                statusString: modelTabs === 0
                    ? user?.isActive ? 'ACTIVO' : 'INACTIVO'
                    : user.person?.isActive === 0 ? 'INACTIVO' : 'ACTIVO',
            }));

            setUsers(content);
            setPagination(prev => ({
                ...prev,
                numberPages: Math.ceil(content.length / prev.size),
            }));

        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const applyFiltersAndPaginate = () => {
        let filteredUsers = users;

        if (searchTerm) {
            filteredUsers = users.filter(user =>
                Object.values(user).some(value =>
                    typeof value === 'string' &&
                    value.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }

        const startIndex = (pagination.page - 1) * pagination.size;
        const paginatedUsers = filteredUsers.slice(startIndex, startIndex + pagination.size);

        setDisplayedUsers(paginatedUsers);
        setPagination(prev => ({
            ...prev,
            numberPages: Math.ceil(filteredUsers.length / prev.size),
        }));
    };

    const configTableHeaders = () => {
        if (modelTabs === 0) {
            setTableHeaders([
                { label: 'ID', key: 'id' },
                { label: 'Estado', key: 'statusString' },
                { label: 'Rol', key: 'roleName' },
                { label: 'Usuario', key: 'username' },
                { label: 'Nombre', key: 'firstNameUser' },
                { label: 'Apellido', key: 'lastNameUser' },
                { label: 'Email', key: 'emailUser' }
            ]);
        } else {
            setTableHeaders([
                { label: 'ID', key: 'id' },
                { label: 'Estado', key: 'statusString' },
                { label: 'Nombre', key: 'firstName' },
                { label: 'Apellido', key: 'lastName' },
                { label: 'Documento', key: 'document' },
                { label: 'Telefono', key: 'phone' },
                { label: 'Email', key: 'email' }
            ]);
        }
    };

    const handlePaginationChange = (page: number) => {
        setPagination(prev => ({ ...prev, page }));
    };

    const redirectToCrud = (mode: 'create' | 'edit', user: any = {}) => {
        let url = '';

        if (mode === 'edit') {
            url = modelTabs === 0
                ? `/crud/edit/${user.id}`
                : `/crud/editPersona/${user.id}`;
        } else {
            url = modelTabs === 0
                ? '/crud/create'
                : '/crud/createPersona';
        }

        navigate(url, {
            state: { user, type: modelTabs === 0 ? 'Usuario' : 'Persona' }
        });
        
    };

    if (!token) {
        return <NotAccess innerView={true} />;
    }

    // Función para exportar los datos a Excel
    const handleDownload = () => {

        const transformedData = users.map(user => {
            const row: { [key: string]: any } = {}; 
    
            tableHeaders.forEach(header => {
                row[header.label] = user[header.key]; 
            });
    
            return row;
        });
    
        const ws = XLSX.utils.json_to_sheet(transformedData);
    
        const wb = XLSX.utils.book_new();
        const sheetName = modelTabs === 0 ? 'Usuarios' : 'Personas';
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace(/[:.-]/g, '');
    
        XLSX.writeFile(wb, `${sheetName.toLowerCase()}_${formattedDate}.xlsx`);
    };
    
    return (
        <Container>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between mb-4 search-container">
                    <div className="search-wrapper">
                        <label htmlFor="search" className="search-label">Buscar</label>
                        <div className="search-field">
                            <FaSearch className="search-icon" />
                            <input
                                type="text"
                                placeholder="Buscar..."
                                className="search-input"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                id="search"
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <FaSquarePlus onClick={() => redirectToCrud('create')} className="create-button ms-2" />
                        <IoCloudDownloadSharp className="download-button ms-2" onClick={handleDownload} /> 
                    </div>
                </div>
                <div className="d-flex align-items-center mb-4">
                    <Button
                        onClick={() => { setModelTabs(0); setPagination(prev => ({ ...prev, page: 1 })); }}
                        className={`bottom-tab ${modelTabs === 0 ? 'active' : ''}`}
                    >
                        Usuarios
                    </Button>
                    <Button
                        onClick={() => { setModelTabs(1); setPagination(prev => ({ ...prev, page: 1 })); }}
                        className={`bottom-tab ${modelTabs === 1 ? 'active' : ''}`}
                    >
                        Personas
                    </Button>
                </div>
                <SimpleBar style={{ maxHeight: '600px' }}>
                    <Table className={animateTable ? 'slide-up-animation' : ''}>
                        <thead className="head_block">
                            <tr>
                                <td className="head_t">Acciones</td>
                                {tableHeaders.map(header => (
                                    <td key={header.key} className="head_t">{header.label}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {displayedUsers.length > 0 ? (
                                displayedUsers.map(user => (
                                    <tr key={user.id || user.usrId || user.perId}>
                                        <td className="table_dashboard">
                                            <TbEditCircle className="bottom-edit" onClick={() => redirectToCrud('edit', user)} />
                                        </td>
                                        {tableHeaders.map(header => (
                                            <td key={header.key} className="table_dashboard">
                                                {user[header.key]}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={tableHeaders.length + 1} className="text-center font-weight-bold my-4">
                                        * No se encontraron resultados
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </SimpleBar>

                {pagination.numberPages > 1 && (
                    <div className="custom-pagination">
                        <button
                            className="pagination-prev"
                            onClick={() => handlePaginationChange(pagination.page - 1)}
                            disabled={pagination.page === 1}
                        >
                            &lt;
                        </button>

                        {(() => {
                            const maxPagesToShow = 10;
                            const currentPage = pagination.page;
                            const totalPages = pagination.numberPages;

                            const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
                            const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

                            const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);

                            return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => {
                                const page = adjustedStartPage + index;
                                return (
                                    <button
                                        key={page}
                                        className={`custom-pagination-item ${currentPage === page ? 'active' : ''}`}
                                        onClick={() => handlePaginationChange(page)}
                                    >
                                        {page}
                                    </button>
                                );
                            });
                        })()}

                        <button
                            className="pagination-next"
                            onClick={() => handlePaginationChange(pagination.page + 1)}
                            disabled={pagination.page === pagination.numberPages}
                        >
                            &gt;
                        </button>
                    </div>
                )}
            </div>
            {loading && <LoadingOverlay />}
        </Container>
    );
};

export default UserList;
