import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { login } from '../../api/auth';
import { jwtDecode } from 'jwt-decode';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Container, Row, Col, Card, Form, Button, Alert, Image } from 'react-bootstrap';
import './LoginForm.css';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
const LoginForm = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState<string>('');
    const { setUser, setToken } = useContext(AuthContext)!;
    const navigate = useNavigate(); // Reincorporar navigate
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [touched, setTouched] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
        setTouched(true);
    };

    const handlePasswordToggle = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const data = await login(credentials);

            if (data.access_token) {
                localStorage.setItem('token', data.access_token);
                const decodedToken = jwtDecode(data.access_token);
                setUser(decodedToken);
                setToken(data.access_token);

                // Redirigir a la página de inicio después del inicio de sesión exitoso
                navigate('/home');
            } else {
                setError('username o password inválidos');
            }
        } catch (error) {
            setError('username o password inválidos');
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordReset = () => {
        setShowResetModal(false);
    };

    return (
        <div className="container-login">
            <img src="/assets/figure_white.png" className="figure_white" alt="figure inetum white top" />
            <img src="/assets/figure_red.png" className="figure_red" alt="figure inetum red bottom" />
            <Container fluid className="d-flex justify-content-center align-items-center h-100">
                <div className="v-card">
                    <Card className="shadow-lg">
                        <Row noGutters>
                            <Col md={6} className="d-none d-md-block responsive_content">
                                <Image
                                    src="/assets/people_working.png"
                                    alt="people working inetum"
                                    fluid
                                    className="img-people"
                                />
                            </Col>
                            <Col md={6} xs={12} className="p-4">
                                <h1 className="mb-4 text-h1">Bienvenido a<span style={{ display: 'block' }}>PROF IT</span></h1>
                                {error && <Alert variant="danger">{error}</Alert>}
                                {!showResetModal ? (
                                    <div className="responsive_form">
                                        <Form onSubmit={handleSubmit}>
                                            <FormGroup controlId="username">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FaEnvelope />
                                                        </span>
                                                    </div>
                                                    <FormControl
                                                        type="text"
                                                        name="username"
                                                        placeholder="Correo electrónico"
                                                        value={credentials.username}
                                                        onChange={handleChange}
                                                        onBlur={() => setTouched(true)}
                                                        isInvalid={!credentials.username && touched}
                                                        required
                                                    />
                                                    <FormControl.Feedback type="invalid">
                                                        Este campo es requerido
                                                    </FormControl.Feedback>
                                                </div>
                                            </FormGroup>
                                            <FormGroup controlId="password">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <FaLock />
                                                        </span>
                                                    </div>
                                                    <FormControl
                                                        type={passwordVisible ? 'text' : 'password'}
                                                        name="password"
                                                        placeholder="Contraseña"
                                                        value={credentials.password}
                                                        onChange={handleChange}
                                                        onBlur={() => setTouched(true)}
                                                        isInvalid={!credentials.password && touched}
                                                        required
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text password-toggle" onClick={handlePasswordToggle}>
                                                            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                                        </span>
                                                    </div>
                                                    <FormControl.Feedback type="invalid">
                                                        Este campo es requerido
                                                    </FormControl.Feedback>
                                                </div>
                                            </FormGroup>
                                            <Button type="submit" className="custom-button">
                                                Ingresar
                                            </Button>
                                        </Form>
                                        <Button className="rec-button" onClick={() => setShowResetModal(true)}>
                                            Recuperar contraseña
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="responsive_form">
                                        <p className="center">
                                            <b>Recuperar Contraseña</b>
                                        </p>
                                        <FormGroup controlId="resetEmail">
                                            <FormLabel>
                                                Ingresa tu correo electrónico
                                            </FormLabel>
                                            <FormControl
                                                type="email"
                                                placeholder="Correo electrónico"
                                                value={resetEmail}
                                                onChange={(e) => setResetEmail(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                        <Button className="custom-button" onClick={handlePasswordReset}>
                                            Enviar
                                        </Button>
                                        <Button className="rec-button" onClick={() => setShowResetModal(false)}>
                                            Cancelar
                                        </Button>
                                    </div>
                                )}
                                <div className="cont_logos_bottom">
                                    <img
                                        className="logo_profit mt-4"
                                        src="/assets/logo_pro_trans_blue.png"
                                        alt="logo PROF IT"
                                    />
                                    <img
                                        className="logo_inetum mt-4"
                                        src="/assets/logo_inetum.jpg"
                                        alt="logo inetum"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Container>
            {loading && <LoadingOverlay />}
        </div>
    );
};

export default LoginForm;
