import React, { useEffect, useState, useRef  } from 'react';
import fetchApi from '../../api/fetchApi';
import { Button, Table } from 'react-bootstrap';
import { FaSquarePlus } from "react-icons/fa6";
import { FaSearch } from 'react-icons/fa';
import { IoCloudDownloadSharp } from "react-icons/io5";
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import * as XLSX from 'xlsx'; 

interface project {
  id: string;
  ceco: string;
  interco: string;
  projectName: string;
  description: string;
  clientProjectName: string;
  documentManagementCode: string;
  financialCloseDate: string;
  projectActivity: string;
  profitCode: string;
  crmCode: string;
  sygesCode: string;
  contractBeginDate: string;
  endDate: string;
  startDate: string;
  financialClosedDate: string;
  validity: string;
  upscale: string;
  inPipe: string;
  projectedPipeInfo: string;
  generalObservation:string;
  payrollAssignment: string;
  internalStaff: string;
  pmo: string;
  externalStaff: string;
  totalStaff: string;
  personalObservation: string;
  initialRevenueValue: string;
  initialCostValue: string;
  initialProfitValue: string;
  initialMarginValue: string;
  eacRevenue: string;
  eacCost: string;
  eacProfit: string;
  eacMargin: string;
  lastModificationDate: string;
  modificationDescription: string;
  planRevenue: string;
  planCost: string;
  planProfit: string;
  planMargin: string;
  revenueDeviation: string;
  costDeviation: string;
  marginDeviation: string;
  isRenewalExpected: string;
  isInCmfBoard: string;
  deliveryModel: string;
  recType: { description: string;  };
  country: { name: string;  };
  company: { name: string;  };
  contract: { code: string; name: string; };
  group: { name: string; };
  sectorClient: { name: string; };
  subSectorClient: { name: string; };
  classification: { name: string; };
  accountManager: { firstName: string; lastName: string; };
  projectManager: { firstName: string; lastName: string; };
  presalesResponsible: { firstName: string; lastName: string; };
  executionState: { name: string; };
  financialState: { name: string; };
  activity: { name: string; };
  upscaleCategory: { name: string; };
  productSize: { name: string; };
  closingMonths: [];
}

const Dashboard: React.FC = () => {
  const [projects, setprojects] = useState<project[]>([]);
  const [busqueda, setBusqueda] = useState<string>('');
  const [pagination, setPagination] = useState({ page: 1, size: 7, numberPages: 1 });
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredprojects, setFilteredprojects] = useState<project[]>([]);
  const navigate = useNavigate();
  const columnRefs = useRef<any[]>([]);
  const [columnFilters, setColumnFilters] = useState<Record<string, string>>({});

  useEffect(() => {
    fetchprojects();
  }, [pagination.page, pagination.size]);

  const fetchprojects = async () => {
    setLoading(true);
    try {
      const response = await fetchApi({
        endpoint: '/projects',
        method: 'GET',
      });
  
      setprojects(response || []);
      setPagination(prev => ({
        ...prev,
        numberPages: Math.ceil(response.length / prev.size),
      }));
    } catch (error) {
      console.error('Error al obtener los datos de projects:', error);
      setprojects([]);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const projectsFiltradas = projects.filter(project =>
      Object.values(project).some(value =>
        value.toString().toLowerCase().includes(busqueda.toLowerCase())
      )
    );
  
    setFilteredprojects(projectsFiltradas);
  }, [busqueda, projects]);
  
  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      numberPages: Math.ceil(filteredprojects.length / prev.size),
    }));
    
  }, [filteredprojects, pagination.size]);

  useEffect(() => {
    const updateStickyColumnWidths = () => {
      let leftPosition = 0; 
  
      columnRefs.current.forEach((col: any, index: number) => {
        if (col) {
          const width = col.offsetWidth;
          
          const header = document.querySelectorAll('.head_t')[index] as HTMLElement;
          if (header) {
            header.style.left = `${leftPosition}px`;
            header.style.width = `${width}px`; 
          }
  
          const cells = document.querySelectorAll(`.table_dashboard:nth-child(${index + 1})`);

          cells.forEach((cell) => {
            const cellElement = cell as HTMLElement;
            cellElement.style.left = `${leftPosition}px`;  
            cellElement.style.width = `${width}px`;  
          });
         
          leftPosition += width;
        }
      });
    };
  
    updateStickyColumnWidths();
  
    window.addEventListener('resize', updateStickyColumnWidths);
  
    return () => {
      window.removeEventListener('resize', updateStickyColumnWidths);
    };
  }, [projects]); 
  
  

  const projectsPaginated = filteredprojects.slice(
    (pagination.page - 1) * pagination.size,
    pagination.page * pagination.size
  );
  
  const handlePaginationChange = (page: number) => {
    if (page >= 1 && page <= pagination.numberPages) {
      setPagination(prev => ({ ...prev, page }));
    }
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredprojects);  
    const wb = XLSX.utils.book_new();  
    XLSX.utils.book_append_sheet(wb, ws, 'Projects');  
    XLSX.writeFile(wb, 'Projects_Report.xlsx');  
  };    

  const handleFilterChange = (column: string, value: string) => {
    setColumnFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  useEffect(() => {
    const applyColumnFilters = () => {
      let filtered = [...projects];
  
      Object.entries(columnFilters).forEach(([column, value]) => {
        if (value) {
          filtered = filtered.filter((project) =>
            column
              .split('.')
              .reduce((obj, key) => (obj as Record<string, any>)?.[key], project)
              ?.toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        }
      });
  
      setFilteredprojects(filtered);
    };
  
    applyColumnFilters();
  }, [columnFilters, projects]);

  
  return (
    <div className="dashboard-container">
      {/* Barra de búsqueda */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="search-wrapper">
          <label htmlFor="search" className="search-label">Buscar</label>
          <div className="search-field">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Buscar..."
              className="search-input"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              id="search"
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <FaSquarePlus className="create-button ms-2" onClick={() => navigate('/crud/createProject')} />
          <IoCloudDownloadSharp className="download-button ms-2" onClick={exportToExcel} />  
        </div>
      </div>

      {/* Tabla de projects */}
        <SimpleBar style={{ maxHeight: '700px' }}>
            <Table className="slide-up-animation table-scroll">
            <thead className="head_block">
              <tr>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[0] = el)}> Acciones </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[1] = el)}>
                  CeCo{/*
                  <input
                    type="text"
                    className="table-filter-input"
                    placeholder="Filtrar..."
                    value={columnFilters.ceco || ""}
                    onChange={(e) => handleFilterChange("ceco", e.target.value)}
                  />*/}
               </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[2] = el)}> Interco </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[3] = el)}> Rec- Type </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[4] = el)}> País </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[5] = el)}> Empresa </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[6] = el)}> Cliente </td>
                <td className="head_t sticky-col" ref={(el) => (columnRefs.current[7] = el)}> Proyecto </td>
                <td className="head_t"> Descripción </td>
                <td className="head_t"> Agrupador (Cliente/Multiceco) </td>
                <td className="head_t"> Sector Cliente </td>
                <td className="head_t"> Subsector Cliente </td>
                <td className="head_t"> Clasificación </td>
                <td className="head_t"> Cliente + nombre de proyecto  </td>
                <td className="head_t"> Código de Proyecto Gestión Documental y ProfIT </td>
                <td className="head_t"> Account Manager </td>
                <td className="head_t"> Project Manager </td>
                <td className="head_t"> Responsable Preventa </td>
                <td className="head_t"> Estado de Ejecución </td>
                <td className="head_t"> Estado Financiero </td>
                <td className="head_t"> Fecha de cierre financiera </td>
                <td className="head_t"> Actividad Proyecto </td>
                <td className="head_t"> Complejidad </td>
                <td className="head_t"> COD Profit </td>
                <td className="head_t"> Activity </td>
                <td className="head_t"> II Categoria Upscale </td>
                <td className="head_t"> BU </td>
                <td className="head_t"> Nombre Profit Center/ OL </td>
                <td className="head_t"> Código CRM </td>
                <td className="head_t"> Código Syges </td>
                <td className="head_t"> Contract Begin date </td>
                <td className="head_t"> Begin Date </td>
                <td className="head_t"> Final Date </td>
                <td className="head_t"> Financial Closed Date </td>
                <td className="head_t"> Vigencia </td>
                <td className="head_t"> Upscale </td>
                <td className="head_t"> Se encontraba en Pipe? </td>
                <td className="head_t"> Información proyectada en Pipe/CRM </td>
                <td className="head_t"> Observaciones generales proyecto </td>
                <td className="head_t"> Asignación nómina </td>
                <td className="head_t"> Personal Interno  </td>
                <td className="head_t"> PMO </td>
                <td className="head_t"> Personal Externo </td>
                <td className="head_t"> Total Personal </td>
                <td className="head_t"> Observaciones Personal </td>
                <td className="head_t"> Openning o valor Inicial Revenue </td>
                <td className="head_t"> Openning o valor Inicial Cost </td>
                <td className="head_t"> Openning o valor Inicial Profit</td>
                <td className="head_t"> Openning o valor Inicial Margin </td>
                <td className="head_t"> Fecha último modificatorio</td>
                <td className="head_t"> Descripción de la modificación </td>
                <td className="head_t"> Plan Revenue</td>
                <td className="head_t"> Plan Cost</td>
                <td className="head_t"> Plan Profit </td>
                <td className="head_t"> Plan Margin </td>
                <td className="head_t"> EAC Revenue </td>
                <td className="head_t"> EAC Cost  </td>
                <td className="head_t"> EAC Profit </td>
                <td className="head_t"> EAC Margin </td>
                <td className="head_t"> Desviación Revenue </td>
                <td className="head_t"> Desviación Costo </td>
                <td className="head_t"> Desviación Margen </td>

                <td className="head_t"> Se espera renovación </td>   
                <td className="head_t"> en Tablero CMF </td>
                <td className="head_t"> Mod. Delivery  </td> 
                <td className="head_t"> Talla </td> 
              </tr>
            </thead>
            <tbody>
                {projectsPaginated.length > 0 ? (
                projectsPaginated.map((project, index) => (
                    <tr key={index}>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[0 + (index * 5)] = el)}>edit</td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[1 + (index * 5)] = el)}> {project.ceco} </td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[2 + (index * 5)] = el)}> {project.interco} </td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[3 + (index * 5)] = el)}> {project.recType.description} </td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[4 + (index * 5)] = el)}> {project.country.name} </td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[5 + (index * 5)] = el)}> {project.company.name} </td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[6 + (index * 5)] = el)}> {project.clientProjectName} </td>
                        <td className="table_dashboard sticky-col" ref={(el) => (columnRefs.current[7 + (index * 5)] = el)}> {project.projectName} </td>
                        <td className="table_dashboard"> {project.description} </td>
                        <td className="table_dashboard"> {project.projectActivity} </td>
                        <td className="table_dashboard"> {project.group.name} </td>
                        <td className="table_dashboard"> {project.sectorClient.name} </td>
                        <td className="table_dashboard"> {project.subSectorClient.name} </td>
                        <td className="table_dashboard"> {project.classification.name} </td>
                        <td className="table_dashboard"> {project.clientProjectName} - {project.projectName} </td>
                        <td className="table_dashboard"> {project.documentManagementCode} </td>
                        <td className="table_dashboard"> {project.accountManager.firstName} {project.accountManager.lastName}</td>
                        <td className="table_dashboard"> {project.projectManager.firstName} {project.projectManager.lastName}</td>
                        <td className="table_dashboard"> {project.presalesResponsible.firstName} {project.presalesResponsible.lastName}</td>
                        <td className="table_dashboard"> {project.executionState.name} </td>
                        <td className="table_dashboard"> {project.financialState.name} </td>
                        <td className="table_dashboard"> {project.financialClosedDate} </td>
                        <td className="table_dashboard"> {project.activity.name} </td>
                        <td className="table_dashboard"> </td>
                        <td className="table_dashboard"> {project.activity.name} </td>
                        <td className="table_dashboard"> {project.upscaleCategory.name} </td>
                        <td className="table_dashboard"> </td>
                        <td className="table_dashboard"> </td>
                        <td className="table_dashboard"> {project.crmCode} </td>
                        <td className="table_dashboard"> {project.sygesCode} </td>
                        <td className="table_dashboard"> {project.contractBeginDate} </td>
                        <td className="table_dashboard"> {project.startDate} </td>
                        <td className="table_dashboard"> {project.endDate} </td>
                        <td className="table_dashboard"> {project.financialClosedDate} </td>
                        <td className="table_dashboard"> {project.validity} </td>
                        <td className="table_dashboard"> {project.upscale} </td>
                        <td className="table_dashboard"> {project.inPipe} </td>
                        <td className="table_dashboard"> {project.projectedPipeInfo} </td>
                        <td className="table_dashboard"> {project.generalObservation} </td>
                        <td className="table_dashboard"> {project.payrollAssignment} </td>
                        <td className="table_dashboard"> {project.internalStaff} </td>
                        <td className="table_dashboard"> {project.pmo} </td>
                        <td className="table_dashboard"> {project.externalStaff} </td>
                        <td className="table_dashboard"> {project.totalStaff} </td>
                        <td className="table_dashboard"> {project.personalObservation} </td>
                        <td className="table_dashboard"> {project.initialRevenueValue} </td>
                        <td className="table_dashboard"> {project.initialCostValue} </td>
                        <td className="table_dashboard"> {project.initialProfitValue} </td>
                        <td className="table_dashboard"> {project.initialMarginValue} </td>
                        <td className="table_dashboard"> {project.lastModificationDate} </td>
                        <td className="table_dashboard"> {project.modificationDescription} </td>
                        <td className="table_dashboard"> {project.planRevenue} </td>
                        <td className="table_dashboard"> {project.planCost} </td>
                        <td className="table_dashboard"> {project.planProfit} </td>
                        <td className="table_dashboard"> {project.planMargin} </td>
                        <td className="table_dashboard"> {project.eacRevenue} </td>
                        <td className="table_dashboard"> {project.eacCost} </td>
                        <td className="table_dashboard"> {project.eacProfit} </td>
                        <td className="table_dashboard"> {project.eacMargin} </td>
                        <td className="table_dashboard"> {project.revenueDeviation} </td>
                        <td className="table_dashboard"> {project.costDeviation} </td>
                        <td className="table_dashboard"> {project.marginDeviation} </td>
                        <td className="table_dashboard"> {project.isRenewalExpected}</td>
                        <td className="table_dashboard"> {project.isInCmfBoard} </td>
                        <td className="table_dashboard"> {project.deliveryModel} </td>
                        <td className="table_dashboard"> {project.productSize.name} </td>
                    </tr>
                ))
                ) : (
                <tr>
                    <td colSpan={83}>
                      No se encontraron projects*
                    </td>
                </tr>
                )}
            </tbody>
            </Table>
        </SimpleBar>
      {/* Paginación */}
      {pagination.numberPages > 1 && (
        <div className="custom-pagination">
          <button
            className="pagination-prev"
            onClick={() => handlePaginationChange(pagination.page - 1)}
            disabled={pagination.page === 1}
          >
            &lt;
          </button>

          {(() => {
            const maxPagesToShow = 10;
            const currentPage = pagination.page;
            const totalPages = pagination.numberPages;

            const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
            const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

            const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);

            return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => {
              const page = adjustedStartPage + index;
              return (
                <button
                  key={page}
                  className={`custom-pagination-item ${currentPage === page ? 'active' : ''}`}
                  onClick={() => handlePaginationChange(page)}
                >
                  {page}
                </button>
              );
            });
          })()}

          <button
            className="pagination-next"
            onClick={() => handlePaginationChange(pagination.page + 1)}
            disabled={pagination.page === pagination.numberPages}
          >
            &gt;
          </button>
        </div>
      )}

      {loading && <LoadingOverlay />}
    </div>
  );
};

export default Dashboard;
