import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import fetchApi from '../../api/fetchApi';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import '../user/formStyles.css';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalAlert from '../template/ModalAlert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface FormData {
    ceco: string;
    interco: string;
    recTypeProId: number;
    countryId: number;
    companyId: number;
    projectName: string;
    description: string;
    contractId: number;
    groupId: number;
    sectorClientId: number;
    subSectorClientId: number;
    classificationId: number;
    clientProjectName: string;
    documentManagementCode: string;
    accountManagerId: number;
    projectManagerId: number;
    presalesResponsibleId: number;
    executionStateId: number;
    financialStateId: number;
    financialCloseDate: string;  
    projectActivity: string;
    complexityId: number;
    profitCode: number;
    activityId: number;
    upscaleCategoryId: number;
    olId: number;
    blId: number;
    buId: number;
    crmCode: number;
    sygesCode: string;
    contractBeginDate: string;  
    startDate: string;  
    endDate: string;  
    financialClosedDate: string;  
    validity: number;
    upscale: string;  
    inPipe: string; 
    projectedPipeInfo: string;
    generalObservation: string;
    payrollAssignment: number;
    internalStaff: number;
    pmo: number;
    externalStaff: number;
    totalStaff: number;
    personalObservation: string;
    initialRevenueValue: number;
    initialCostValue: number;
    initialProfitValue: number;
    initialMarginValue: number;
    eacRevenue: number;
    eacCost: number;
    eacProfit: number;
    eacMargin: number;
    lastModificationDate: string;  
    modificationDescription: string;
    planRevenue: number;
    planCost: number;
    planProfit: number;
    planMargin: number;
    revenueDeviation: number;
    costDeviation: number;
    marginDeviation: number;
    isRenewalExpected: string;  
    isInCmfBoard: string;  
    deliveryModel: string;
    productSizeId: number;
}

interface FormField {
    label: string;
    type: string;
    options?: { label: string; value: any }[];
    onChange?: (date: Date | null) => void;
}

const validationSchema = Yup.object().shape({
    ceco: Yup.string().required('Este campo es requerido'),
    interco: Yup.string().required('Este campo es requerido'),
    recTypeProId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    countryId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    companyId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    projectName: Yup.string().required('Este campo es requerido'),
    description: Yup.string().required('Este campo es requerido'),
    contractId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    groupId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    sectorClientId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    subSectorClientId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    classificationId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    clientProjectName: Yup.string().required('Este campo es requerido'),
    documentManagementCode: Yup.string().required('Este campo es requerido'),
    accountManagerId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    projectManagerId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    presalesResponsibleId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    executionStateId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    financialStateId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    financialCloseDate: Yup.string().required('Este campo es requerido'),
    projectActivity: Yup.string().required('Este campo es requerido'),
    complexityId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    profitCode: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    activityId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    upscaleCategoryId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    olId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    blId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    buId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    crmCode: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    sygesCode: Yup.string().required('Este campo es requerido'),
    contractBeginDate: Yup.string().required('Este campo es requerido'),
    startDate:  Yup.string().required('Este campo es requerido'),
    endDate:  Yup.string().required('Este campo es requerido'),
    financialClosedDate:  Yup.string().required('Este campo es requerido'),
    validity: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    upscale: Yup.string().required('Este campo es requerido'),
    inPipe: Yup.string().required('Este campo es requerido'),
    projectedPipeInfo: Yup.string().required('Este campo es requerido'),
    generalObservation: Yup.string().required('Este campo es requerido'),
    payrollAssignment: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    internalStaff: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    pmo: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    externalStaff: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    totalStaff: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    personalObservation: Yup.string().required('Este campo es requerido'),
    initialRevenueValue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    initialCostValue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    initialProfitValue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    initialMarginValue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    eacRevenue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    eacCost: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    eacProfit: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    eacMargin: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    lastModificationDate:  Yup.string().required('Este campo es requerido'),
    modificationDescription: Yup.string().required('Este campo es requerido'),
    planRevenue: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),  
    planCost: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),      
    planProfit: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),        
    planMargin: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),      
    revenueDeviation: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value), 
    costDeviation: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    marginDeviation: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
    isRenewalExpected: Yup.string().required('Este campo es requerido'),
    isInCmfBoard: Yup.string().required('Este campo es requerido'),
    deliveryModel: Yup.string().required('Este campo es requerido'),    
    productSizeId: Yup.number()
        .typeError('Este campo es requerido')
        .required('Este campo es requerido')
        .transform((value, originalValue) => originalValue === "" ? undefined : value),
});

const formFields: Partial<Record<keyof FormData, FormField>> = {
    ceco: { label: 'CeCo', type: 'text' },
    interco: { label: 'Interco', type: 'text' },
    recTypeProId: { label: 'Rec- Type', type: 'number' },
    countryId: { label: 'País', type: 'number' },
    companyId: { label: 'Empresa', type: 'number' },
    projectName: { label: 'Proyecto', type: 'text' },
    description: { label: 'Descripción', type: 'textarea' },

    sectorClientId: { label: 'Sector Cliente', type: 'number' },
    subSectorClientId: { label: 'Subsector Cliente', type: 'number' },
    classificationId: { label: 'Clasificación', type: 'number' },
    clientProjectName: { label: 'Cliente + nombre de proyecto ', type: 'text' },
    documentManagementCode: { label: 'Código de Gestión Documental', type: 'text' },
    accountManagerId: { label: 'Account Manager', type: 'number' },
    projectManagerId: { label: 'Project Manager', type: 'number' },
    presalesResponsibleId: { label: 'Responsable Preventa', type: 'number' },
    executionStateId: { label: 'Estado de Ejecución', type: 'number' },
    financialStateId: { label: 'Estado Financiero', type: 'number' },
    financialCloseDate: { label: 'Fecha de Cierre Financiero', type: 'date' },
    projectActivity: { label: 'Actividad del Proyecto', type: 'text' },
    complexityId: { label: 'Complejidad', type: 'number' },
    profitCode: { label: 'Código de Beneficio', type: 'text' },
    activityId: { label: 'Actividad', type: 'number' },
    upscaleCategoryId: { label: 'II Categoria Upscale', type: 'number' },
    olId: { label: 'OL (Organización Legal)', type: 'number' },
    blId: { label: 'BL (Línea de Negocio)', type: 'number' },
    buId: { label: 'BU (Unidad de Negocio)', type: 'number' },
    crmCode: { label: 'Código CRM', type: 'text' },
    sygesCode: { label: 'Código SyGES', type: 'text' },
    contractBeginDate: { label: 'Contract Begin date', type: 'date' },
    startDate: { label: 'Begin Date', type: 'date' },
    endDate: { label: 'Final Date', type: 'date' },
    financialClosedDate: { label: 'Financial Closed Date', type: 'date' },
    validity: { label: 'Vigencia', type: 'number' },
    upscale: { label: 'Upscale', type: 'text' },
    inPipe: { label: '¿Se encontraba en Pipe?', type: 'number' },
    projectedPipeInfo: { label: 'Información proyectada en Pipe/CRM', type: 'textarea' },
    generalObservation: { label: 'Observaciones generales proyecto', type: 'textarea' },
    payrollAssignment: { label: 'Asignación de Nómina', type: 'number' },
    internalStaff: { label: 'Personal Interno', type: 'number' },
    pmo: { label: 'PMO', type: 'number' },
    externalStaff: { label: 'Personal Externo', type: 'number' },
    totalStaff: { label: 'Total de Personal', type: 'number' },
    personalObservation: { label: 'Observación Personal', type: 'textarea' },
    initialRevenueValue: { label: 'Openning o valor Inicial  Revenue', type: 'number' },
    initialCostValue: { label: 'Openning o valor Inicial Cost', type: 'number' },
    initialProfitValue: { label: 'Openning o valor Inicial Profit', type: 'number' },
    initialMarginValue: { label: 'Openning o valor Inicial Margin', type: 'number' },
    eacRevenue: { label: 'Último Modificatorio EAC Revenue', type: 'number' },
    eacCost: { label: 'Último Modificatorio EAC Cost', type: 'number' },
    eacProfit: { label: 'Último Modificatorio EAC Profit', type: 'number' },
    eacMargin: { label: 'Último Modificatorio EAC Margin ', type: 'number' },
    lastModificationDate: { label: 'Fecha último modificatorio', type: 'date' },
    modificationDescription: { label: 'Descripción de Modificación', type: 'textarea' },
    planRevenue: { label: 'Plan Revenue', type: 'number' },
    planCost: { label: 'Plan Cost', type: 'number' },
    planProfit: { label: 'Plan Profit', type: 'number' },
    planMargin: { label: 'Plan Margin', type: 'number' },
    revenueDeviation: { label: 'Desviación Revenue', type: 'number' },
    costDeviation: { label: 'Desviación Costos', type: 'number' },
    marginDeviation: { label: 'Desviación Margen', type: 'number' },
    isRenewalExpected: { label: '¿Se espera renovación?', type: 'number' },
    isInCmfBoard: { label: '¿Está en el tablero CMF?', type: 'number' },
    deliveryModel: { label: 'Mod. Delivery ', type: 'text' },
    productSizeId: { label: 'Talla', type: 'number' },
    contractId: { label: 'Contrato', type: 'number' },
    groupId: { label: 'Grupo', type: 'number' }
};

  const CrudProject = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<'success' | 'error'>('success');
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [recTypeOptions, setRecTypeOptions] = useState<any[]>([]);
    const [sectorClientOptions, setSectorClientOptions] = useState<any[]>([]);
    const [subSectorClientOptions, setSubSectorClientOptions] = useState<any[]>([]);
    const [classificationOptions, setClassificationOptions] = useState<any[]>([]);
    const [personOption, setPersonOption] = useState<any[]>([]);
    const [executionStateOption, setExecutionStateOption] = useState<any[]>([]);
    const [financialStateOption, setFinancialStateOption] = useState<any[]>([]);
    const [complexityOption, setComplexityOption] = useState<any[]>([]);
    const [activityOption, setActivityOption] = useState<any[]>([]);
    const [upscaleCategoryOption, setUpscaleCategoryOption] = useState<any[]>([]);
    const [olOption, setOlOption] = useState<any[]>([]);
    const [blOption, setBlOption] = useState<any[]>([]);
    const [buOption, setBuOption] = useState<any[]>([]);
    const [productSizeOption, setProductSizeOption] = useState<any[]>([]);
    const [groupsOption, setGroupsOption] = useState<any[]>([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
    });
    const [dateValues, setDateValues] = useState<Record<string, Date | null>>({
        financialCloseDate: null,
        contractBeginDate: null,
        startDate: null,
        endDate: null,
        lastModificationDate: null,
    });
    
    const yesNoOptions = [
        { label: 'SI', value: 'Y' },
        { label: 'NO', value: 'N' }
    ];

    const fetchData = async (endpoint: string, setState: React.Dispatch<React.SetStateAction<any[]>>) => {
        try {
            const response = await fetchApi({ endpoint, method: 'GET' });
            setState(response);
        } catch (error) {
            console.error(`Error al obtener los datos de ${endpoint}:`, error);
        }
    };

    useEffect(() => {
        fetchData('/properties/parent/110', setRecTypeOptions);           // recTypeOptions
        fetchData('/properties/parent/225', setSectorClientOptions);      // sectorClientOptions
        fetchData('/properties/parent/236', setSubSectorClientOptions);   // subSectorClientOptions
        fetchData('/properties/parent/247', setClassificationOptions);    // classificationOptions
        fetchData('/persons', setPersonOption);                           // personOption
        fetchData('/states?type=PYT_EJECUCION', setExecutionStateOption);  // executionStateOption
        fetchData('/states?type=PYT_FINANCIERO', setFinancialStateOption); // financialStateOption
        fetchData('/properties/parent/258', setComplexityOption);         // complexityOption
        fetchData('/properties/parent/269', setActivityOption);           // activityOption
        fetchData('/properties/parent/280', setUpscaleCategoryOption);    // upscaleCategoryOption
        fetchData('/ols', setOlOption);                                   // olOption
        fetchData('/business-lines', setBlOption);                        // blOption
        fetchData('/business-units', setBuOption);                        // buOption
        fetchData('/properties/parent/291', setProductSizeOption);        // productSizeOption
        fetchData('/groups', setGroupsOption);        // setGroupsOption
    }, []);
    

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        setIsSubmitting(true);

        try {
            await fetchApi({
                endpoint: '/projects',
                method: 'POST',
                body: data,
                useToken: true,
            });
            setAlertMessage('Operación creada con éxito.');
            setAlertType('success');
            setTimeout(() => {
                navigate('/project/dashboard');
            }, 3000);
        } catch (error) {
            setAlertMessage('Error al crear la operación.');
            setAlertType('error');
            if (error instanceof AxiosError) {
                console.error('Error al enviar el formulario:', error.response?.data || error.message);
            } else {
                console.error('Error al enviar el formulario:', error);
            }
        } finally {
            setIsSubmitting(false);
            setModalAlertOpen(true);
        }
    };

    const onDateChange = (key: string, date: Date | null) => {
        setDateValues((prev) => ({ ...prev, [key]: date }));
        setValue(key as keyof FormData, date ? date.toISOString().split('T')[0] : '');
    };
    return (
        <div className="layout-app user-crud">
            <section className="header_crud header_crud_fijo">
                <button className="button-back" onClick={() => navigate(-1)}>←</button>
                <h4>CREAR OPERACIÓN</h4>
                <button type="submit" className="button-submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
                    {isSubmitting ? 'Enviando...' : 'Crear'}
                </button>
            </section>

            <form className="form-user scroll_form" onSubmit={handleSubmit(onSubmit)}>
                {Object.keys(formFields).map((key) => {
                    const field = formFields[key as keyof FormData];
                    const errorMessage = errors[key as keyof FormData]?.message;

                    if (!field) {
                        return null; 
                    }

                    if (key === 'recTypeProId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {recTypeOptions.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }
                                       
                    if (key === 'sectorClientId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {sectorClientOptions.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }

                    if (key === 'subSectorClientId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {subSectorClientOptions.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }

                    if (key === 'classificationId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {classificationOptions.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }
                    if (key === 'accountManagerId' || key === 'projectManagerId' || key === 'presalesResponsibleId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {personOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.firstName} {option.lastName}
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }    
                    if (key === 'executionStateId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {executionStateOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }   
                    if (key === 'financialStateId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {financialStateOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }      
                    if (key === 'complexityId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {complexityOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }          
                    if (key === 'activityId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {activityOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }  
                    if (key === 'upscaleCategoryId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {upscaleCategoryOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }        
                    if (key === 'olId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {olOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }   
                    if (key === 'blId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {blOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }   
                    if (key === 'buId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {buOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }   
                    if (key === 'productSizeId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {productSizeOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }        
                    if (key === 'groupId') {
                        
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {groupsOption.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }     
                    if (key === 'isRenewalExpected' || key === 'isInCmfBoard' || key === 'inPipe') {
                        return (
                            <div key={key} className="item-form">
                                <label>{field.label}</label>
                                <select 
                                    {...register(key as keyof FormData)} 
                                    defaultValue="" 
                                    className={`input ${errorMessage ? 'input-error' : ''}`}
                                >
                                    <option value="" disabled>Seleccionar</option>

                                    {yesNoOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label} 
                                        </option>
                                    ))}
                                </select>
                                {errorMessage && <span className="error-message">{errorMessage}</span>}
                            </div>
                        );
                    }                                          
                    return (
                        <div key={key} className="item-form">
                            <label>{field.label}</label>

                            {field.type === 'select' ? (
                                <select {...register(key as keyof FormData)}>
                                    <option value="" className="gray" disabled>Seleccionar</option>
                                    {field.options?.map((option: { label: string; value: any }) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            ) : field.type === 'date' ? (
                                <DatePicker
                                    {...register(key as keyof FormData)}
                                    selected={dateValues[key] || null}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(date) => onDateChange(key, date)}
                                />
                            ) : field.type === 'textarea' ? (
                                <textarea {...register(key as keyof FormData)} />
                            ) : (
                                <input
                                    type={field.type}
                                    {...register(key as keyof FormData)}
                                    placeholder={field.label}
                                />
                            )}

                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </div>
                    );
                })}
            </form>
            <ModalAlert
                isOpen={modalAlertOpen}
                onClose={() => setModalAlertOpen(false)}
                message={alertMessage}
                type={alertType}
            />
        </div>
    );
};

export default CrudProject;