import axios from 'axios';
import config from '../configuration/config';

export const login = async (credentials: { username: string; password: string }) => {
    try {
        const response = await axios.post(`${config.API_URL}/auth/login`, credentials);
        //console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        console.error('API login error:', error);
        throw new Error('Login failed');
    }
};

export const getToken = () => localStorage.getItem('access_token');
export const logout = () => {
    localStorage.removeItem('access_token');
};
