import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaPowerOff, FaUsers, FaChartBar, FaArrowLeft, FaHome } from 'react-icons/fa';
import { GoGraph } from "react-icons/go";
import { FaFolderTree } from "react-icons/fa6";
import { GiHumanTarget } from "react-icons/gi";
import { CiPower } from "react-icons/ci";
import { AuthContext } from '../../context/AuthContext';
import './Sidebar.css';

const getInitials = (firstName: string, lastName: string) => {
    const firstInitial = firstName?.charAt(0).toUpperCase() || '';
    const lastInitial = lastName?.charAt(0).toUpperCase() || '';
    return firstInitial + lastInitial;
};

const Sidebar: React.FC = () => {
    const [expanded, setExpanded] = useState(false);
    const authContext = useContext(AuthContext);
    const user = authContext?.user;
    const token = authContext?.token;
    const handleLogout = authContext?.handleLogout;

    const initials = getInitials(user?.firstName || '', user?.lastName || '');
    const toggleMenu = () => setExpanded(!expanded);

    const renderTooltip = (text: string) => (
        <Tooltip id={`tooltip-${text}`}>
            {text}
        </Tooltip>
    );

    if (!token) {
        return null;
    }
    const handleLogoutClick = async () => {
        if (handleLogout) {
            await handleLogout();
            window.location.href = '/login';
        }
    };

    return (
        <div className={`sidebar ${expanded ? 'expanded' : ''}`}>
            {expanded && (
                <div className="user-info">
                    <div className="user-details">
                        <div className="avatar">
                            {initials || 'NN'}
                        </div>
                        <div className="text-info">
                            <p>
                                <span className="color_text">{user?.firstName || 'Nombre Usuario'} {user?.lastName || 'Apellido Usuario'}</span> <br />
                                <span className="text_small">{user?.role || 'Rol'}</span>
                            </p>
                        </div>
                        <button onClick={toggleMenu} className="btn close-btn">
                            <FaArrowLeft />
                        </button>
                    </div>
                </div>
            )}
            {!expanded && (
                <button onClick={toggleMenu} className="btn toggle-btn nav-no-expanded">
                    ☰
                </button>
            )}
            <Nav defaultActiveKey="/knowledgeMap" className="flex-column">
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('Inicio')}>
                        <Nav.Link as={Link} to="/home" className="nav-link nav-no-expanded">
                            <FaHome />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as={Link} to="/home" className="nav-link nav-expanded">
                        <FaHome className="icon-max" />inicio
                    </Nav.Link>
                )}
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('Mapa de conocimiento')}>
                        <Nav.Link as={Link} to="/knowledgeMap" className="nav-link nav-no-expanded">
                            <FaChartBar />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as={Link} to="/knowledgeMap" className="nav-link nav-expanded">
                        <FaChartBar className="icon-max" />Mapa de Conocimiento
                    </Nav.Link>
                )}
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('Seguimiento HA')}>
                        <Nav.Link as={Link} to="/trackingHa" className="nav-link nav-no-expanded">
                            <GoGraph />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as={Link} to="/trackingHa" className="nav-link nav-expanded">
                        <GoGraph className="icon-max" />Seguimiento HA
                    </Nav.Link>
                )}
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('Usuarios')}>
                        <Nav.Link as={Link} to="/users" className="nav-link nav-no-expanded">
                            <FaUsers />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as={Link} to="/users" className="nav-link nav-expanded">
                        <FaUsers className="icon-max" /> Usuarios
                    </Nav.Link>
                )}
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('H.A')}>
                        <Nav.Link as={Link} to="/ha" className="nav-link nav-no-expanded">
                            <GiHumanTarget />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as={Link} to="/ha" className="nav-link nav-expanded">
                        <GiHumanTarget className="icon-max" /> H.A
                    </Nav.Link>
                )}
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('Proyectos')}>
                        <Nav.Link as={Link} to="/project/dashboard" className="nav-link nav-no-expanded">
                            <FaFolderTree  />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as={Link} to="/project/dashboard" className="nav-link nav-expanded">
                        <FaFolderTree     className="icon-max" /> Proyectos
                    </Nav.Link>
                )}
                {!expanded ? (
                    <OverlayTrigger placement="right" overlay={renderTooltip('Cerrar sesión')}>
                        <Nav.Link as="button" onClick={handleLogoutClick} className="nav-link text-danger nav-no-expanded">
                            <FaPowerOff />
                        </Nav.Link>
                    </OverlayTrigger>
                ) : (
                    <Nav.Link as="button" onClick={handleLogoutClick} className="nav-link text-danger nav-expanded">
                        <CiPower className="icon-max" /> Cerrar sesión
                    </Nav.Link>
                )}
            </Nav>
            <div className="logo-container">
                <img src="/assets/logo_pro_trans_white.png" alt="Logo" className="logo" />
            </div>
        </div>
    );
};

export default Sidebar;
